* {
  box-sizing: border-box; }

ul, li {
  margin: 0;
  padding: 0; }

li {
  list-style-type: none; }

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

.centered, .container__xs, .container__sm {
  margin: 0 auto; }

.left {
  float: left; }

.right {
  float: right; }

.container__xs {
  width: 400px; }

.container__sm {
  max-width: 600px; }

.input__row {
  margin-bottom: 1.5rem;
  position: relative; }

.invoice__form input {
  border: 1px solid #ccc; }

.rows {
  display: flex;
  flex-direction: column; }

.columns {
  display: flex; }

.rows--reverse {
  flex-direction: column-reverse; }

.u-block {
  display: block; }

.mb--lrg {
  margin-bottom: 2rem; }

.mb--xs {
  margin-bottom: 0.5rem; }

.mb--sm {
  margin-bottom: 1rem; }

.mr--lrg {
  margin-right: 2rem; }
