html {
  font-size: 62.5%;
  min-height: 100%; }

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  min-height: 100%;
  background: #F9F9F9;
  color: #333; }

a, a:visited {
  text-decoration: none;
  color: #186bad;
  font-weight: 700;
  cursor: pointer; }

h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #333;
  letter-spacing: 1px; }

h2 {
  font-size: 18px; }

#app {
  min-height: 100%; }

input,
button {
  width: 100%; }

input {
  padding: 1rem;
  border: none; }

::placeholder {
  color: #bbb; }

a.btn, .btn {
  border: none;
  background: #186bad;
  color: #fff;
  padding: 1rem;
  display: block;
  cursor: pointer;
  text-align: center;
  font-weight: normal; }

.btn--secondary {
  background: #ccc; }

.invoice__add-btn {
  margin-top: 3rem; }

.sign-in {
  background-color: #2c2f3d;
  min-height: 100%;
  display: flex;
  align-items: center; }

.sign-in input {
  border-radius: 3rem;
  padding: 1rem 2rem; }

a.nav__link {
  display: block;
  padding: 2rem;
  color: #8b8fa2;
  font-size: 14px; }

a.nav__link:hover {
  background: #3a3d50; }

a.nav__link.active {
  background: #186bad;
  color: #fff; }

nav li {
  border-bottom: 1px solid #464B5E; }

nav li:last-child {
  border: none; }

.nav__container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  padding: 1.5rem 0;
  width: 300px;
  background: #2c2f3d;
  background: -moz-linear-gradient(top, #2c2f3d 0%, #292c3d 100%);
  background: -webkit-linear-gradient(top, #2c2f3d 0%, #292c3d 100%);
  background: linear-gradient(to bottom, #2c2f3d 0%, #292c3d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2f3d', endColorstr='#292c3d',GradientType=0 );
  text-transform: uppercase; }

.main-content {
  margin-left: 300px;
  padding: 2rem 4rem; }

.invoice__row {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  padding: 1.5rem; }

.invoice__row div {
  color: #333;
  font-weight: normal; }

.row > div {
  text-overflow: ellipsis;
  overflow: hidden; }

.invoice__row:hover {
  background: #f0f0f0; }

.u-bold {
  font-weight: bold; }

.overlay--bg {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  top: 0;
  left: 0; }

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 3rem;
  z-index: 20;
  min-width: 400px;
  max-width: 600px; }

input:focus:invalid {
  border: 1px solid red; }

input {
  border: 1px solid #ccc; }

*:focus {
  outline: none; }

.takeover-link {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0; }

.input__radio {
  width: auto;
  display: block;
  margin: 0 auto; }

.input__row label {
  margin-bottom: 1rem;
  display: block;
  font-weight: bold; }

.u-visibility-hidden {
  visibility: hidden; }

.search,
.top-nav-header {
  background: #F2F2F4;
  padding: 2rem;
  margin: -2rem -4rem 0; }

.search input {
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none; }

.btn {
  border-radius: 3rem; }

.btn-stack {
  width: 125px; }

.btn-stack--sm {
  width: 75px; }

.btn.btn--left {
  border-radius: 20px 0 0 20px; }

.btn.btn--right {
  border-radius: 0 20px 20px 0; }

.name {
  font-weight: bold; }

.log-out-btn {
  border-radius: 20px;
  background: transparent;
  color: #8b8fa2;
  padding: 1.5rem;
  border: 1px solid #464B5E;
  display: block;
  margin: 2rem auto;
  width: 85%;
  text-transform: uppercase;
  font-size: 14px; }

.login {
  text-transform: uppercase;
  color: #8b8fa2;
  text-align: center;
  font-size: 18px;
  margin-bottom: 3.5rem; }

.info__row {
  margin-top: 0;
  margin-bottom: 0.5rem; }

.invoice__type {
  padding: 0.5rem; }

.bold {
  font-weight: 700; }

.info {
  margin: 2rem 0 3rem; }

.btn--close {
  background: #000;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 700;
  width: 25px; }

.welcome {
  color: #8b8fa2;
  padding: 1.5rem; }

.welcome a {
  color: #fff; }

textarea {
  width: 100%;
  display: block;
  padding: 1.5rem;
  border: 1px solid #ccc; }

.hamburger {
  color: #fff;
  display: none; }

@media (max-width: 768px) {
  .nav__container {
    width: 100%;
    position: static;
    height: auto; }
  .nav__container nav {
    background: linear-gradient(to bottom, #2c2f3d 0%, #292c3d 100%);
    z-index: 100;
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    height: 100%;
    display: none; }
  .hamburger {
    display: block;
    background: transparent;
    border: none;
    text-align: right;
    padding: 0 20px; }
  .hamburger__line {
    height: 3px;
    margin: 5px 0;
    width: 30px;
    background: #fff; }
  .main-content {
    margin-left: 0;
    padding: 2rem; }
  .nav__container nav.nav--active {
    display: block; }
  .btn-stack {
    width: 105px; }
  .search, .top-nav-header {
    margin: -2rem -2rem 0; } }
